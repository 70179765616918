import { networkAction } from 'helpers/network/networkAction';

import {
  SEARCH_LOCATIONS,
  NEW_LOCATION,
} from 'constants/locations';

import * as Api from 'api';

export const searchLocations = params => async dispatch => networkAction(
  dispatch, SEARCH_LOCATIONS, Api.searchLocations, [params],
);

export const newLocation = body => async dispatch => networkAction(
  dispatch, NEW_LOCATION, Api.newLocation, [body],
);

import { networkAction } from 'helpers/network/networkAction';

import {
  NEW_QUOTE,
  GET_QUOTE_BY_ID,
} from 'constants/quotes';

import * as Api from 'api';

export const newQuote = body => async dispatch => networkAction(
  dispatch, NEW_QUOTE, Api.newQuote, [body],
);

export const getQuoteByID = quoteID => async dispatch => networkAction(
  dispatch, GET_QUOTE_BY_ID, Api.getQuoteByID, [quoteID],
);

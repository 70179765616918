import {
  POST,
  GET,
} from 'constants/methods';

const service = 'v3/quote-service';
const quotes = `/${service}/quotes`;

export function newQuote(body) {
  const url = `${quotes}/multistop`;
  return {
    service,
    method: POST,
    body,
    url,
  };
}

export function getQuoteByID(quoteID) {
  const url = `${quotes}/${quoteID}`;
  return {
    service,
    method: GET,
    url,
  };
}

// import moment from 'moment';

export const COMMODITIES = [
    { name: 'Dairy', id: 1 },
    { name: 'Machinery', id: 2 },
    { name: 'Dry F.A.K', id: 4 },
    { name: 'Food Product', id: 5 },
    { name: 'Alcohol', id: 6 },
    { name: 'Vitamins', id: 7 },
    { name: 'Electronics', id: 8 },
    { name: 'Tote', id: 9 },
  ];
  export const SERVICE_LEVEL_COLORS = {
    'economy': '#4caf50',
    'premium': '#ffc107',
    'guaranteed': '#f44336',
  };
  export const SERVICE_LEVEL_ICONS = {
    'economy': 'https://storage.googleapis.com/archer-app/economy.png',
    'premium': 'https://storage.googleapis.com/archer-app/premium.png',
    'guaranteed': 'https://storage.googleapis.com/archer-app/guaranteed.png',
  };
  export const STRIKE_COMMODITIES = [
    'Haz Mat',
    'Ice Cream',
  ];
  export const REMOVE = 'REMOVE';
  export const ADD = 'ADD';
  export const INCHES = 'inches';
  export const LTL = 'LTL';
  export const TL = 'TL';
  export const FEET = 'feet';
  export const DRY = 'Dry';
  export const REEFER = 'Reefer';
  export const CONSIGNEE = 'Drop-off';
  export const SHIPPER = 'Pick-up';
  export const TL_TEMPERATURE = /^((-20)|(80)|(-[1,0]?[0-9]{1})|([0-7]?[0-9]{1}))$/;
  export const LTL_TEMPERATURE = /^((80)|([4-7]{1}[0-9]{1}))$/;
  export const NUMBER = /^-?\d*\.?\d*$/;
  export const QUOTE_MAX_STOPS = 4;
  export const MAX_LOCATION_AUTOCOMPLETE_ITEMS = 8;
  export const DEFAULT_BODY = {
    service: LTL,
    commodityDescriptionId: 4,
    mode: DRY,
    dryVanOnly: false,
    shippers: [
      {
        zip: '',
        accessorialIds: [],
        date: null,
      },
    ],
    consignees: [
      {
        zip: '',
        accessorialIds: [],
      },
    ],
    units: {
      palletDimensions: [],
      totalDimensions: {
        totalWeight: '',
      },
    },
    reefer: {
      reeferTemp: '',
      reeferCont: false,
    },
  };
  
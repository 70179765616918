import { networkAction } from 'helpers/network/networkAction';

import {
  GET_CURRENT_USER,
  SIGNIN,
} from 'constants/users';

import * as Api from 'api';

export const getCurrentUser = body => async dispatch => networkAction(
  dispatch, GET_CURRENT_USER, Api.getCurrentUser, [body],
);

export const signin = body => async dispatch => networkAction(
  dispatch, SIGNIN, Api.signin, [body],
);

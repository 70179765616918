import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// helpers
import { positiveNumber } from 'helpers/app';

// styles
import styles from './styles';
import { FEET, INCHES } from 'constants/app';

class FormPalletDimension extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    pallet: PropTypes.object,
    close: PropTypes.func,
    open: PropTypes.bool,
    onSave: PropTypes.func,
  };

  state = {
    units: INCHES,
    length: 0,
    width: 0,
    height: 0,
    count: 0,
    stackable: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.pallet);
    }
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;

    switch (name) {
      case 'length':
      case 'width':
      case 'height':
        if (!positiveNumber(value) && (value !== '')) {
          return;
        }
        break;
      default:
    }

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = name => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  save() {
    const { onSave, close } = this.props;
    const {
      units,
      length,
      width,
      height,
      count,
      stackable,
    } = this.state;

    const pallet = {
      units,
      length: Number(length),
      width: Number(width),
      height: Number(height),
      count: Number(count),
      stackable,
    };

    onSave(pallet);
    close();
  }

  init(pallet) {
    this.setState({ ...pallet });
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      close,
    } = this.props;

    const {
      units,
      length,
      width,
      height,
      stackable,
      count,
    } = this.state;

    const error = (length === '')
    || (width === '')
    || (count === '')
    || (height === '');

    return (
      <Dialog
        fullScreen
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Custom Dimension
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the size of your pallets
          </DialogContentText>
          <Grid container className={classes.line} spacing={24}>
            <Grid item xs={6}>
              <TextField
                id="count"
                value={count}
                helperText={count === '' ? 'Required' : ''}
                error={count === ''}
                label="Count"
                placeholder={'Pallet Qty'}
                fullWidth
                onChange={this.handleChange('count')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="commodity-description">Units</InputLabel>
                <Select
                  value={units}
                  onChange={this.handleChange('units')}
                  inputProps={{
                    name: 'Units',
                    id: 'units',
                  }}
                >
                  <MenuItem
                    value={INCHES}
                  >
                    {INCHES}
                  </MenuItem>
                  <MenuItem
                    value={FEET}
                  >
                    {FEET}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={24} className={classes.line}>
            <Grid item xs={4}>
              <TextField
                id="length"
                label="Length per pallet"
                value={length}
                helperText={length === '' ? 'Required' : ''}
                error={length === ''}
                placeholder={'in inches'}
                fullWidth
                onChange={this.handleChange('length')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="width"
                value={width}
                helperText={width === '' ? 'Required' : ''}
                error={width === ''}
                label="Width per pallet"
                placeholder={'in inches'}
                fullWidth
                onChange={this.handleChange('width')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="height"
                value={height}
                helperText={height === '' ? 'Required' : ''}
                error={height === ''}
                label="Height per pallet"
                placeholder={'in inches'}
                fullWidth
                onChange={this.handleChange('height')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={stackable}
                      onChange={this.handleCheckboxChange('stackable')}
                      value="stackable"
                      color="primary"
                    />
                  )}
                  label="Stackable"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          className={classes.dialogActions}
        >
          <Button
            onClick={close}
          >
            <CloseIcon className={classes.leftIcon} />
            &nbsp;Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            disabled={error}
            onClick={this.save.bind(this)}
          >
            <SaveIcon className={classes.leftIcon} />
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormPalletDimension));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';

// custom
import CustomerFinder from 'components/CustomerFinder';
import FormPalletDimension from 'components/FormPalletDimension';
import Pallet from 'components/Pallet';
import PalletChecker from 'components/PalletChecker';
import Stops from 'components/Stops';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

// helpers
import { positiveNumber, validNumber, formattedPrice } from 'helpers/app';

// styles
import styles from './styles.js';

import fake from './fake.json';

// constants
import {
  LTL,
  TL,
  DEFAULT_BODY,
  REMOVE,
  ADD,
  DRY,
  REEFER,
  LTL_TEMPERATURE,
  TL_TEMPERATURE,
  COMMODITIES,
  INCHES,
  STRIKE_COMMODITIES,
  SERVICE_LEVEL_COLORS,
  SERVICE_LEVEL_ICONS,
} from 'constants/app';

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    client: PropTypes.object,
    newQuote: PropTypes.func,
    searchCustomers: PropTypes.func,
    searchLocations: PropTypes.func,
    newLocation: PropTypes.func,
    checkPallets: PropTypes.func,
    accessorials: PropTypes.array,
    holidays: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    this.container = React.createRef();
    this.state = {
      body: JSON.parse(JSON.stringify(DEFAULT_BODY)),
      disabled: false,
      nextClicked: false,
      palletDimensionOpen: false,
      errorsFunctions: [],
    };

    this.gatherErrorFunction({
      errorFunction: this.hasError.bind(this),
      section: `about`,
    }, ADD);
  }

  componentDidMount() {
    this.resize();
  }

  resize() {
    const { client } = this.props;
    if (this.container && this.container.current.clientHeight){
      console.log('resize', this.container.current.clientHeight);
    }
    if (client && client.invoke && this.container && this.container.current) {
      console.log(this.container.current.clientHeight);
      client.invoke('resize', {
        width: 360,
        height: this.container.current.clientHeight > 500 ? 500 : this.container.current.clientHeight,
        });
    }
  }

  requote() {
    this.setState({
      quoteResult: undefined,
      quoteLoading: true,
    });
    this.handleCalculate();
  }

  gatherErrorFunction(errorHandler, action) {
    const { errorsFunctions } = this.state;
    let i;

    switch (action) {
      case ADD:
        errorsFunctions.push(errorHandler);
        console.log('Added', errorHandler.section);
        break;
      case REMOVE:
        i = errorsFunctions.findIndex(e => e.section === errorHandler.section);
        if (i !== undefined) {
          errorsFunctions.splice(i, 1);
          console.log('Removed', errorHandler.section);
        }
        break;
      default:
        break;
    }

    console.log(errorsFunctions);
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;
    const { body } = this.state;

    if (name === 'reeferTemp') {
      body.reefer[name] = value;
      this.setState({
        body,
      });
      return;
    }

    if (name === 'totalWeight') {
      body.units.totalDimensions[name] = value;
      this.setState({
        body,
      });
      return;
    }

    body[name] = value;

    this.setState({
      body,
    });
    this.resize();
  };

  handleCheckboxChange = name => (event) => {
    const { target } = event;
    const { checked } = target;
    const { body } = this.state;

    body[name] = checked;

    this.setState({
      body,
    });
  };

  handlePalletDelete(index) {
    const { body } = this.state;
    const { units } = body;
    const { palletDimensions } = units;

    units.palletDimensions.splice(index, 1);

    this.setState({ body });
  }

  handleDimensionEdit(index) {
    const { body } = this.state;
    const { units } = body;
    const { palletDimensions } = units;

    this.setState({
      selectedPalletIndex: index,
      palletToUpdate: { ...palletDimensions[index] },
    });
    this.openPalletDimension();
  }

  handleUpdatedPalletDimension(updatedPallet) {
    const { onChange } = this.props;
    const { body } = this.state;
    const { units } = body;

    const { selectedPalletIndex } = this.state;

    units.palletDimensions[selectedPalletIndex] = updatedPallet;
    this.setState({ body });
  }

  handlePalletTmp(e) {
    const { target } = e;
    const { value } = target;

    if (positiveNumber(value) || (value === '')) {
      this.setState(prevState => ({
        tmpPallet: {
          ...prevState.tmpPallet,
          count: Number(value),
        },
      }), this.reset);
    }
  }

  handlePalletFocus() {
    this.setState({
      tmpPallet: {
        units: INCHES,
        length: 48,
        width: 40,
        height: 48,
        count: '',
        stackable: false,
        key: Date.now(),
      },
    }, this.reset);
  }

  addPallet() {
    const { body } = this.state;
    const { units } = body;

    const { tmpPallet } = this.state;

    if ((tmpPallet === undefined)
    || (tmpPallet && (tmpPallet.count === ''))
    || (tmpPallet && (tmpPallet.count === 0))) {
      this.setState({
        tmpPallet: undefined,
      }, this.reset);
      this.handlePalletFocus();
      return;
    }

    units.palletDimensions.push(tmpPallet);

    this.setState({
      body,
    }, this.reset);
    this.handlePalletFocus();
  }

  openPalletDimension() {
    this.setState({ palletDimensionOpen: true });
  }

  closePalletDimension() {
    this.setState({ palletDimensionOpen: false });
  }

  hasError() {
    const { body } = this.state;
    const {
      service,
      units,
      mode,
      reefer,
    } = body;

    const {
      reeferTemp,
    } = reefer;

    const { totalDimensions, palletDimensions } = units;
    const { totalWeight } = totalDimensions;

    if ((mode === REEFER) && (service === LTL) && !LTL_TEMPERATURE.test(reeferTemp)) {
      return true;
    }

    if ((mode === REEFER) && (service === TL) && !TL_TEMPERATURE.test(reeferTemp)) {
      return true;
    }

    if (palletDimensions.length === 0) {
      return true;
    }

    if ((totalWeight === 0) || (totalWeight === '')) {
      return true;
    }

    return false;
  }

  handleCalculate() {
    const { errorsFunctions } = this.state;
    const { body } = this.state;
    let hasError = false;
    for (const k in errorsFunctions) {
      if (errorsFunctions.hasOwnProperty(k)) {
        const element = errorsFunctions[k];
        const elementHasError = element.errorFunction();
        hasError = hasError || elementHasError;
        if (elementHasError) {
          console.log('error');
        }
      }
    }
    console.log(hasError);
    if (hasError) {
      this.setState({ nextClicked: true });
    } else {
      this.setState({ disabled: true });
      const _body = JSON.parse(JSON.stringify(body));

      if (_body.mode === DRY) {
        delete _body.reefer;
      }

      this.newQuote(_body);
    }
  }

  async newQuote(body) {
    const { newQuote } = this.props;
    this.setState({
      quoteLoading: true,
    });
    const resp = await newQuote(body);
    if (resp.success) {
      this.setState({
        quoteResult: resp.payload,
        quoteLoading: false,
      }, this.resize);
    } else {
      this.setState({
        quoteLoading: false,
        disabled: false,
      }, this.resize);
    }
  }

  render() {
    const { 
      classes, 
      client,
      searchCustomers,
      searchLocations,
      newLocation,
      accessorials,
      checkPallets,
      reset,
    } = this.props;

    const {
      body,
      disabled,
      tmpPallet,
      palletDimensionOpen,
      palletToUpdate,
      nextClicked,
      quoteLoading,
      quoteResult,
      customer,
    } = this.state;

    const {
      service,
      mode,
      reeferTemp,
      reeferCont,
      commodityDescriptionId,
      holidays,
      dryVanOnly,
      units,
      key,
      customerID,
    } = body;

    const { totalDimensions, palletDimensions } = units;

    const { totalWeight } = totalDimensions;

    console.log(body)
    let temperatureError = '';
    if ((mode === REEFER) && (service === LTL) && !LTL_TEMPERATURE.test(reeferTemp)) {
      temperatureError = 'Temperature must be between 40℉ and 80℉';
    }

    if ((mode === REEFER) && (service === TL) && !TL_TEMPERATURE.test(reeferTemp)) {
      temperatureError = 'Temperature must be between -20℉ and 80℉';
    }

    if (quoteLoading) {
      return (
        <div ref={this.container} className={classes.container}>
          <Grid 
            container 
            spacing={8}
            justify="center"
          >
            <Grid item style={{ textAlign: 'center' }}>
              <CircularProgress />
              <Typography>Calculating Quote</Typography>
            </Grid>
          </Grid>
        </div>
      );
    }

    console.log(quoteResult);

    if (quoteResult) {
      return (
      <div ref={this.container} className={classes.container}>
        <Grid 
          container
          spacing={8}
          justify="space-between"
        >
          <Grid item xs={12}>
            <Typography variant="h6">Quote #{quoteResult.id}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Created At
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography xs={6}>
              <b>{moment(quoteResult.createdAt).calendar()}</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Total Miles
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography xs={6}>
              <b>{quoteResult.totalMiles} miles</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              Transit Time
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Typography xs={6}>
              <b>{quoteResult.transitTime} Hours</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {
            quoteResult.serviceLevels.map(l => (
              <Grid item xs={12} key={l.id}>
                <Paper className={classes.paper} style={{ borderLeft: `solid 3px ${SERVICE_LEVEL_COLORS[l.serviceLevel]}`}}>
                  <Grid container spacing={8}>
                    <Grid item>
                      <img src={SERVICE_LEVEL_ICONS[l.serviceLevel]} style={{ height: 25 }}/>
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontWeight: 500, fontSize: 16, textTransform: 'capitalize' }}>
                        {l.serviceLevel}
                      </Typography>
                      <Typography color="textSecondary">
                        {l.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container justify="space-between">
                    <Grid item>
                      <Grid container spacing={8}>
                        <Grid item>
                          <Typography variant="caption" color="textSecondary">
                            Base Rate
                          </Typography>
                          <Typography>
                            {formattedPrice(l.baseRate)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" color="textSecondary">
                            Fuel Rate
                          </Typography>
                          <Typography>
                            {formattedPrice(l.fuelRate)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" color="textSecondary">
                            Extra
                          </Typography>
                          <Typography>
                            {formattedPrice(l.extra)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                          Total
                      </Typography>
                      <Typography variant="subtitle1">
                        <b>{formattedPrice(l.totalRate)}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container>
                    <Grid item>
                      <Typography>
                        Delivery date from <b>{moment.parseZone(l.from).format('YYYY/MM/DD')}</b> to <b>{moment.parseZone(l.to).format('YYYY/MM/DD')}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))
          }
          <Grid 
            item xs={6} 
            style={{ paddingTop: 10 }}
          >
            <Button 
              style={{ width: '100% '}} 
              variant="contained"
              onClick={() => this.setState({ 
                quoteResult: undefined, 
                disabled: undefined, 
                nextClicked: false, 
              }, this.resize)}
            >
              Edit Quote
            </Button>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 10 }} onClick={reset}>
            <Button style={{ width: '100% '}} color="primary" variant="contained">
              New Quote
            </Button>
          </Grid>
        </Grid>
      </div>
      );
    }

    return (
      <div ref={this.container} className={classes.container}>
        <Grid 
          container 
          spacing={8}
        >
          <Grid item xs={12}>
            <Button
              style={{
                width: '100%'
              }}
              variant="contained"
              onClick={reset}
            >
              Reset Quote Form
            </Button>
          </Grid>
          <Grid item xs={12}>
            <CustomerFinder
              searchCustomers={searchCustomers}
              onChange={this.handleChange('customerId')}
              onCustomerSelect={(customer) => this.setState({ customer })}
              error={(customerID === undefined) && nextClicked}
              selected={customer}
              helperText={'Required'}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset" disabled={disabled} style={{ width: '100%' }}>
              <FormLabel component="legend">
                <Typography color="textSecondary" variant="caption">Service</Typography>
              </FormLabel>
              <RadioGroup
                aria-label="service-type"
                name="service-type"
                value={service}
                onChange={this.handleChange('service')}
              >
                <FormControlLabel
                  value={LTL}
                  control={(
                    <Radio
                      color="primary"
                    />
                  )}
                  label={LTL}
                />
                <FormControlLabel
                  value={TL}
                  control={(
                    <Radio
                      color="primary"
                    />
                  )}
                  label={TL}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset" disabled={disabled}  style={{ width: '100%' }}>
              <FormLabel component="legend">
              <Typography color="textSecondary" variant="caption">Mode</Typography>
              </FormLabel>
              <RadioGroup
                aria-label="mode"
                name="mode"
                value={mode}
                onChange={this.handleChange('mode')}
              >
                <FormControlLabel
                  value={DRY}
                  control={(
                    <Radio
                      color="primary"
                    />
                  )}
                  label={DRY}
                />
              <FormControlLabel
                  value={REEFER}
                  control={(
                    <Radio
                      color="primary"
                    />
                  )}
                  label={REEFER}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {
            mode === REEFER
            && (
              <Grid item xs={12}>
                <TextField
                  id="Temperature"
                  label="Temperature"
                  placeholder={'in Farenheit'}
                  helperText={
                    service === LTL
                      ? 'Between 40℉ and 80℉'
                      : 'Between -20℉ and 80℉'
                  }
                  fullWidth
                  value={reeferTemp}
                  onChange={this.handleChange('reeferTemp', true)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={(temperatureError !== '') && nextClicked}
                  disabled={(mode === DRY) || disabled}
                />
              </Grid>
            )
          }
          {
            mode === REEFER
            && (
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={reeferCont}
                        onChange={this.handleCheckboxChange('reeferCont')}
                        value="Continuous Running"
                        color="primary"
                      />
                    )}
                    label="Continuous Running"
                    disabled={disabled || (mode === DRY)}
                  />
                </FormGroup>
              </Grid>
            )
          }
          {
                mode === DRY
                && (
                  <Grid item>
                    <FormGroup row>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={dryVanOnly}
                            onChange={this.handleCheckboxChange('dryVanOnly')}
                            value="Dry Van Only"
                            color="primary"
                          />
                        )}
                        label="Dry Van Only"
                        disabled={disabled || (mode === REEFER)}
                      />
                    </FormGroup>
                  </Grid>
                )
              }
          <Grid item xs={12}>
            <FormControl fullWidth disabled={disabled}>
              <InputLabel htmlFor="commodity-description">Commodity Description</InputLabel>
              <Select
                value={commodityDescriptionId}
                onChange={this.handleChange('commodityDescriptionId')}
                inputProps={{
                  name: 'Commodity Description',
                  id: 'commodity-description',
                }}
              >
                {
                  COMMODITIES.map(commodity => (
                    <MenuItem
                      value={commodity.id}
                      key={commodity.id}
                    >
                      {commodity.name}
                    </MenuItem>
                  ))
                }
                {
                  STRIKE_COMMODITIES.map(commodity => (
                    <MenuItem
                      value={-1}
                      key={commodity}
                      disabled
                    >
                      <span className={classes.strikethrough}>
                        {commodity}
                      </span>
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={disabled}
              id="totalWeight"
              label="Total Weight"
              placeholder="In Pounds"
              fullWidth
              helperText={'Required'}
              value={totalWeight}
              onChange={this.handleChange('totalWeight', true)}
              InputLabelProps={{
                shrink: true,
              }}
              error={((totalWeight === '') || (totalWeight === 0)) && nextClicked}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={disabled}
              fullWidth
              error={(palletDimensions.length === 0) && nextClicked}
              onFocus={this.handlePalletFocus.bind(this)}
              onChange={this.handlePalletTmp.bind(this)}
              onBlur={this.addPallet.bind(this)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.addPallet(true);
                }
              }}
              value={tmpPallet ? tmpPallet.count : ''}
              id="palletCount"
              label="Pallet Count"
              placeholder="Enter Pallet Count"
              helperText={palletDimensions.length === 0 ? 'Required' : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={8}>
              {
                palletDimensions.map((p, index) => (
                  <Grid item>
                    <Pallet
                      key={p.key}
                      {...p}
                      onDelete={this.handlePalletDelete.bind(this)}
                      onDimensionEdit={this.handleDimensionEdit.bind(this)}
                      index={Number(index)}
                      disabled={disabled}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PalletChecker
              units={units}
              checkPallets={checkPallets}
            />
          </Grid>
          <Grid item>
            <Stops
              csr
              key={key}
              requote={this.requote.bind(this)}
              disabled={disabled}
              service={service || LTL}
              accessorials={accessorials}
              searchLocations={searchLocations}
              onChange={(b) => {
                let { body } = this.state;
                body = {...body, ...b};
                this.setState({ body });
              }}
              shippers={body.shippers}
              consignees={body.consignees}
              nextClicked={nextClicked}
              newLocation={newLocation}
              holidays={holidays}
              gatherErrorFunction={this.gatherErrorFunction.bind(this)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              style={{
                width: '100%'
              }}
              variant="contained"
              color="primary"
              onClick={this.handleCalculate.bind(this)}
            >
              Calculate
            </Button>
          </Grid>
          <FormPalletDimension
            open={palletDimensionOpen}
            close={this.closePalletDimension.bind(this)}
            pallet={palletToUpdate}
            onSave={this.handleUpdatedPalletDimension.bind(this)}
          />
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(App);

export default theme => ({
  container: {
    width: '100%',
    maxWidth: 600,
    overflow: 'hidden',
    position: 'relative',
  },
  paper: {
    width: 'calc(100% - 25px)',
    padding: 10,
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

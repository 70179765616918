import {
  GET,
  POST,
} from 'constants/methods';


const service = 'v3/account';
const users = `/${service}/users`;

export function getCurrentUser() {
  const url = `${users}/current`;
  return {
    service,
    method: GET,
    url,
  };
}

export function signin(body) {
  const url = `${users}/signin`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}
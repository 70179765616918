export default theme => ({
  container: {
    padding: 16,
    marginBottom: 20,
  },
  strikethrough: {
    textDecoration: 'line-through',
  },
  line: {
    paddingBottom: 20,
  },
  next: {
    marginTop: 20,
    marginBottom: 20,
  },
});

import * as Colors from '@material-ui/core/colors/';

export default {
  primary: {
    color: Colors.blue,
    light: Colors.grey[50],
    main: Colors.blue[800],
    dark: Colors.blue[900],
    contrastText: '#fff',
  },
  secondary: Colors.grey,
  error: Colors.red,
  contrastThreshold: 6,
  tonalOffset: 0.8,
};

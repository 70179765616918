import {
  GET,
} from 'constants/methods';

const service = 'v3/quote-service';
const accessorials = `/${service}/accessorials`;

export function getAccessorials() {
  const url = `${accessorials}`;
  return {
    service,
    method: GET,
    url,
  };
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import styles from './styles';

class Signin extends Component {
  static propTypes = {
    classes: PropTypes.object,
    signin: PropTypes.func,
    init: PropTypes.func,
    client: PropTypes.any,
  };

  state = {
    email: '',
    password: '',
    loading: false,
  };

  constructor(...args) {
    super(...args);
    this.container = React.createRef();
  }

  componentDidMount() {
    this.resize();
  }

  resize() {
    const { client } = this.props;
    if (this.container){
      console.log('resize', this.container.current.clientHeight);
    }
    if (client && client.invoke && this.container && this.container.current) {
      console.log(this.container.current.clientHeight);
      client.invoke('resize', {
        width: 360,
        height: this.container.current.clientHeight > 500 ? 500 : this.container.current.clientHeight,
        });
    }
  }

  async signin() {
    const { signin, init } = this.props;
    const { email, password } = this.state;
    this.setState({ loading: true });
    const resp = await signin({ email, password });
    init();
    this.setState({ loading: false });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      password,
      loading,
    } = this.state;

    return (
        <div className={classes.container} ref={this.container}>
          <Grid container justify="center">
            {
              loading
                ? (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography>
                          Signin in ...
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CircularProgress color="primary"/>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography>
                        Please, login using your Archer Credentials
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="standard-name"
                        label="Email"
                        fullWidth
                        className={classes.textField}
                        value={email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="standard-password-input"
                        label="Password"
                        onKeyPress={(e) => { if (e.key === 'Enter') this.signin(); }}
                        className={classes.textField}
                        fullWidth
                        type="password"
                        value={password}
                        onChange={this.handleChange('password')}
                        autoComplete="current-password"
                        margin="normal"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        onClick={this.signin.bind(this)}
                      >
                        Get Started
                      </Button>
                    </Grid>
                  </React.Fragment>
                )
            }
          </Grid>
        </div>
    );
  }
}

export default withStyles(styles)(Signin);

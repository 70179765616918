import { networkAction } from 'helpers/network/networkAction';

import {
  CHECK_PALLETS,
  PALLET_COMMODITIES,
} from 'constants/pallets';

import * as Api from 'api';

export const checkPallets = body => async dispatch => networkAction(
  dispatch, CHECK_PALLETS, Api.checkPallets, [body],
);

export const palletCommodities = body => async dispatch => networkAction(
  dispatch, PALLET_COMMODITIES, Api.palletCommodities, [body],
);

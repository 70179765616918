import { networkAction } from 'helpers/network/networkAction';

import {
  GET_HOLIDAYS,
} from 'constants/holidays';

import * as Api from 'api';

export const getHolidays = () => async dispatch => networkAction(
  dispatch, GET_HOLIDAYS, Api.getHolidays, [],
);

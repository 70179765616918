export function positiveNumber(number) {
    return !isNaN(parseFloat(number)) && isFinite(number) && parseFloat(number) >= 0;
  }
  
  export function validNumber(number) {
    return !isNaN(parseFloat(number)) && isFinite(number) && parseFloat(number);
  }
  
  export function formattedPrice(x) {
    let neg = false;
    if (x < 0) {
      neg = true;
    }
  
    const price = Math.abs(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    if (neg) {
      return `-$${(price)}`;
    }
  
    return `$${(price)}`;
  }
  
  export function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }
  
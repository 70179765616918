import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

// custom
import Stop from '../Stop';

// styles
import styles from './styles';
import { CONSIGNEE, SHIPPER, QUOTE_MAX_STOPS } from 'constants/app';

class Stops extends Component {
  static propTypes = {
    csr: PropTypes.bool,
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    service: PropTypes.string,
    accessorials: PropTypes.object,
    searchLocations: PropTypes.func,
    shippers: PropTypes.array,
    consignees: PropTypes.array,
    holidays: PropTypes.array,
    onChange: PropTypes.func,
    requote: PropTypes.func,
    newLocation: PropTypes.func,
    nextClicked: PropTypes.bool,
    gatherErrorFunction: PropTypes.func,
  };

  handleStopChange = (type, index) => (e, key) => {
    const { shippers, consignees, onChange, disabled, requote } = this.props;
    const { target } = e;
    const { value } = target;
    const _shippers = shippers.slice();
    const _consignees = consignees.slice();

    switch (type) {
      case SHIPPER:
        _shippers[index][key] = value;
        onChange({
          shippers: _shippers,
        });
        break;
      case CONSIGNEE:
        _consignees[index][key] = value;
        onChange({
          consignees: _consignees,
        });
        break;
      default:
        break;
    }

    if (disabled) {
      setTimeout(requote, 500);
    }
  }

  addStop = type => () => {
    const { shippers, consignees, onChange } = this.props;
    const _shippers = shippers.slice();
    const _consignees = consignees.slice();
    switch (type) {
      case SHIPPER:
        _shippers.push({
          zip: '',
          facility: '',
          accessorialIds: [],
          date: '',
          time: '',
        });
        onChange({
          shippers: _shippers,
        });
        break;
      case CONSIGNEE:
        _consignees.push({
          zip: '',
          facility: '',
          accessorialIds: [],
          date: '',
          time: '',
        });
        onChange({
          consignees: _consignees,
        });
        break;
      default:
        break;
    }
  }; 

  removeStop(type, index) {
    const { shippers, consignees, onChange } = this.props;
    const _shippers = shippers.slice();
    const _consignees = consignees.slice();

    switch (type) {
      case SHIPPER:
        _shippers.splice(index, 1);
        onChange({
          shippers: _shippers,
        });
        break;
      case CONSIGNEE:
        _consignees.splice(index, 1);
        onChange({
          consignees: _consignees,
        });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      classes,
      service,
      accessorials,
      shippers,
      consignees,
      disabled,
      searchLocations,
      nextClicked,
      newLocation,
      gatherErrorFunction,
      csr,
      holidays,
      requote,
    } = this.props;

    const addStopAllowed = shippers.length + consignees.length < QUOTE_MAX_STOPS;

    return (
      <div>
        {
          shippers.map((s, i) => (
            <Stop
              {...s}
              holidays={holidays}
              key={`stop_${i}`}
              csr={csr}
              requote={requote}
              gatherErrorFunction={gatherErrorFunction}
              newLocation={newLocation}
              nextClicked={nextClicked}
              searchLocations={searchLocations}
              disabled={disabled}
              accessorials={accessorials}
              index={Number(i)}
              type={SHIPPER}
              onChange={this.handleStopChange(SHIPPER, Number(i))}
              onDelete={shippers.length > 1
                ? this.removeStop.bind(this)
                : undefined
              }
              service={service}
            />
          ))
        }
        {
          disabled !== true
            ? (
              <Grid container className={classes.next} justify="center">
                <Grid item>
                  <Tooltip
                    title={`Add Pick-up, ${QUOTE_MAX_STOPS} Stops Max`}
                  >
                    <Button
                      variant="outlined"
                      onClick={this.addStop(SHIPPER)}
                      disabled={!addStopAllowed}
                    >
                      <Add />
                      Pick-up
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            )
            : (
              <br />
            )
        }
        {
          consignees.map((c, i) => (
            <Stop
              {...c}
              key={`stop_${i}`}
              gatherErrorFunction={gatherErrorFunction}
              newLocation={newLocation}
              nextClicked={nextClicked}
              searchLocations={searchLocations}
              disabled={disabled}
              accessorials={accessorials}
              index={Number(i)}
              type={CONSIGNEE}
              onChange={this.handleStopChange(CONSIGNEE, Number(i))}
              onDelete={consignees.length > 1
                ? this.removeStop.bind(this)
                : undefined
              }
              service={service}
            />
          ))
        }
        {
          disabled !== true
            ? (
              <Grid container className={classes.next} justify="center">
                <Grid item>
                  <Tooltip
                    title={`Add Drop-off, ${QUOTE_MAX_STOPS} Stops Max`}
                  >
                    <Button
                      variant="outlined"
                      onClick={this.addStop(CONSIGNEE)}
                      disabled={!addStopAllowed}
                    >
                      <Add />
                      Drop-off
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            )
            : (
              <br />
            )
        }
      </div>
    );
  }
}

export default withStyles(styles)(Stops);

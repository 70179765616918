import { networkAction } from 'helpers/network/networkAction';

import {
  GET_ACCESSORIALS,
} from 'constants/accessorials';

import * as Api from 'api';

export const getAccessorials = body => async dispatch => networkAction(
  dispatch, GET_ACCESSORIALS, Api.getAccessorials, [body],
);

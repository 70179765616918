import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// custom
import Signin from 'components/Signin';
import App from 'components/App';
import NotificationCenter from 'components/NotificationCenter';

// actions
import * as Actions from 'actions';

// styles
import styles from './styles';

const mapStateToProps = state => ({
  user: state.user,
  errors: state.errors,
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  signin: (...args) => dispatch(Actions.signin(...args)),
  newQuote: (...args) => dispatch(Actions.newQuote(...args)),
  searchCustomers: (...args) => dispatch(Actions.searchCustomers(...args)),
  searchLocations: (...args) => dispatch(Actions.searchLocations(...args)),
  newLocation: (...args) => dispatch(Actions.newLocation(...args)),
  getHolidays: (...args) => dispatch(Actions.getHolidays(...args)),
  getAccessorials: (...args) => dispatch(Actions.getAccessorials(...args)),
  checkPallets: (...args) => dispatch(Actions.checkPallets(...args)),
});

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getCurrentUser: PropTypes.func,
    newQuote: PropTypes.func,
    searchCustomers: PropTypes.func,
    searchLocations: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { getCurrentUser } = this.props;
    let client;
    try {
      client = window.ZAFClient.init();
    } catch (error) {
      console.log('ZAFClient not defined');
    }

    this.state = {
      loading: true,
      client,
    };

    this.init();
  }

  async init() {
    const { getAccessorials, getHolidays, getCurrentUser } = this.props;
    const resps = await Promise.all([
      getAccessorials(),
      getHolidays(),
      getCurrentUser(),
    ]);

    this.setState({
      accessorials: resps[0].payload,
      holidays: resps[1].payload,
      loading: false,
      key: new Date().getTime()
    });
  }

  reset() {
    console.log('reset');
    this.setState({
      key: new Date().getTime(),
    });
  }

  render() {
    const { 
      classes,
      user,
      signin,
      newQuote,
      searchCustomers,
      searchLocations,
      newLocation,
      checkPallets,
      errors,
    } = this.props;
    const { loading, client, holidays, accessorials, key } = this.state;

    return (
      <NotificationCenter
        errors={errors}
      >
        <div 
          className={classes.container} 
          style={{ 
            padding: client ? 10 : 20,
            width: client ? 'calc(100% - 20px)' : 'calc(100% - 40px)',
          }}
        >
          <Grid container justify="center">
            {
              loading
                ? (
                  <Grid item>
                    <CircularProgress color="primary" />
                  </Grid>
                ) : (
                  <Grid item>
                    {
                      user.uuid && accessorials
                        ? (
                          <App 
                            key={key}
                            reset={this.reset.bind(this)}
                            client={client}
                            newQuote={newQuote}
                            searchCustomers={searchCustomers}
                            searchLocations={searchLocations}
                            newLocation={newLocation}
                            accessorials={accessorials}
                            holidays={holidays}
                            checkPallets={checkPallets}
                          />
                        ) : (
                          <Signin 
                            signin={signin}
                            client={client}
                            init={this.init.bind(this)}
                          />
                        )
                    }
                  </Grid>
                )
            }
          </Grid>
        </div>
      </NotificationCenter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Root));

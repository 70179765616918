import { networkAction } from 'helpers/network/networkAction';

import {
  SEARCH_CUSTOMERS,
} from 'constants/customers';

import * as Api from 'api';

export const searchCustomers = (search, start, limit) => async dispatch => networkAction(
  dispatch, SEARCH_CUSTOMERS, Api.searchCustomers, [search, start, limit],
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// styles
import styles from './styles';

class DatePick extends Component {
  static propTypes = {
    csr: PropTypes.bool,
    ltl: PropTypes.bool,
    holidays: PropTypes.array,
    classes: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.any,
    helperText: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { holidays } = this.props;
    const holi = {};

    for (const k in holidays) {
      if (holidays.hasOwnProperty(k)) {
        const day = holidays[k];
        holi[moment(day.date).format('L')] = day.name;
      }
    }

    this.state = {
      holidays: holi,
    };
  }

  renderDay = (d, selectedDate, dayInCurrentMonth) => {
    const { classes, value, csr, ltl } = this.props;
    const { holidays } = this.state;
    const isPast = moment().diff(moment(d)) > 0;
    const isToday = moment().format('L') === moment(d).format('L');
    const isSelected = moment(value).format('L') === moment(d).format('L');
    const isWeekEnd = (moment(d).format('dddd') === 'Saturday') || (moment(d).format('dddd') === 'Sunday');

    const holiday = holidays[moment(d).format('L')];
    let isDisabled = isPast;
    if (ltl) {
      isDisabled = isPast || isWeekEnd || holiday;
    }
    if (csr) {
      isDisabled = isPast && !isToday;
    }

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlight]: isSelected,
      [classes.today]: isToday,
      [classes.disabled]: isDisabled,
      [classes.holiday]: holiday !== undefined,
    });

    if (holiday !== undefined) {
      return (
        <Tooltip title={holiday}>
          <IconButton className={dayClassName}>
            <span> { moment(d).format('D') } </span>
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <div>
        <IconButton className={dayClassName}>
          <span> { moment(d).format('D') } </span>
        </IconButton>
      </div>
    );
  };

  shouldDisableDate(d) {
    const { csr, ltl } = this.props;
    const { holidays } = this.state;
    const isPast = moment().diff(moment(d)) > 0;
    const isToday = moment().format('L') === moment(d).format('L');
    const isWeekEnd = (moment(d).format('dddd') === 'Saturday') || (moment(d).format('dddd') === 'Sunday');

    if (csr) return isPast && !isToday;
    
    if (ltl) return isPast || (holidays[moment(d).format('L')] !== undefined) || isWeekEnd;

    return isPast;
  }

  render() {
    const { 
      label,
      value,
      helperText,
      onChange,
      error,
    } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format={'MM/dd/yyyy'}
          style={{ marginTop: 0 }}
          shouldDisableDate={this.shouldDisableDate.bind(this)}
          placeholder={'Select a Date'}
          allowKeyboardControl
          margin="normal"
          label={label}
          autoOk
          value={value}
          fullWidth
          helperText={helperText}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
          renderDay={this.renderDay}
          error={error}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(DatePick);

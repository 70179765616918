import { networkAction } from 'helpers/network/networkAction';

import {
  UPLOAD_LOAD_FILE,
  GET_LOAD_FILES,
} from 'constants/loads';

import * as Api from 'api';

export const uploadLoadFile = (loadID, file, descriptionID) => async dispatch => networkAction(
  dispatch, UPLOAD_LOAD_FILE, Api.uploadLoadFile, [loadID, file, descriptionID],
);

export const getLoadFiles = loadID => async dispatch => networkAction(
  dispatch, GET_LOAD_FILES, Api.getLoadFiles, [loadID],
);

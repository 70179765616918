import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Check from '@material-ui/icons/Check';
import Warning from '@material-ui/icons/Warning';

// styles
import styles from './styles';

class PalletChecker extends Component {
  static propTypes = {
    classes: PropTypes.object,
    units: PropTypes.object,
    checkPallets: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { units } = this.props;
    this.state = {
      loading: false,
      error: false,
      resp: {},
      units: JSON.parse(JSON.stringify(units)),
    };
  }

  componentWillReceiveProps(nextProps) {
    const { units } = this.state;
    if (this.somethingChanged(units, nextProps.units)) {
      this.check(nextProps.units.palletDimensions, nextProps.units.totalDimensions.totalWeight);
      this.setState({ units: JSON.parse(JSON.stringify(nextProps.units)) });
    }

    if ((nextProps.units.totalDimensions.totalWeight === '')
    || (nextProps.units.palletDimensions.length === 0)) {
      this.setState({ resp: {} });
    }
  }

  somethingChanged(_units, units) {
    console.log('somethingChanged', _units, units );
    if (units.palletDimensions.length === 0) {
      return false;
    }

    if ((units.totalDimensions.totalWeight === undefined) || (units.totalDimensions.totalWeight === '')) {
      return false;
    }

    // weight changed
    if (_units.totalDimensions.totalWeight !== units.totalDimensions.totalWeight) {
      return true;
    }

    // pallet count changed
    if (_units.palletDimensions.length !== units.palletDimensions.length) {
      return true;
    }

    // pallet values changed
    for (const k in units.palletDimensions) {
      if (units.palletDimensions.hasOwnProperty(k)) {
        const _pallet = _units.palletDimensions[k];
        const pallet = units.palletDimensions[k];
        if (JSON.stringify(_pallet) !== JSON.stringify(pallet)) {
          return true;
        }
      }
    }

    return false;
  }

  async check(pallets, totalWeight) {
    const { checkPallets } = this.props;
    this.setState({ loading: true, resp: {} });
    const body = pallets.slice();
    let palletCount = 0;
    pallets.map(p => palletCount += p.count);
    const avgPalletWeight = totalWeight / palletCount;

    for (const k in body) {
      if (body.hasOwnProperty(k)) {
        body[k].weight = avgPalletWeight;
      }
    }

    const resp = await checkPallets(body);

    if (resp.success) {
      this.setState({
        resp: resp.payload,
        loading: false,
        error: false,
      });
    } else {
      this.setState({
        error: true,
        resp: {},
        loading: false,
      });
    }
  }

  render() {
    const {
      loading,
      resp,
      error,
    } = this.state;

    const {
      weight,
      pallets,
      feet,
    } = resp;

    if (loading) {
      return (
        <Grid
          container
          spacing={8}
        >
          <Grid item>
            <CircularProgress size={20} />
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
            >
              Checking Equipment Compatibility ...
            </Typography>
          </Grid>
        </Grid>
      );
    }

    if (error) {
      return (
        <Grid
          container
          spacing={8}
        >
          <Grid item>
            <Chip
              avatar={(
                <Avatar style={{ color: 'white', background: '#ff9800' }}>
                  <Warning />
                </Avatar>
              )}
              label={'Equipment compatibility issue'}
            />
          </Grid>
        </Grid>
      );
    }

    if (pallets) {
      return (
        <Grid
          container
          spacing={8}
        >
          <Grid item>
            <Chip
              avatar={(
                <Avatar style={{ color: 'white', background: '#4caf50' }}>
                  <Check />
                </Avatar>
              )}
              label={`Total: ${feet} ft, ${weight} lb, ${pallets} Pallets `}
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <div>
        
      </div>
    );
  }
}

export default withStyles(styles)(PalletChecker);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Add from '@material-ui/icons/AddCircleOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grow from '@material-ui/core/Grow';

// custom
import LocationFinder from 'components/LocationFinder';
import Card from 'components/Card';
import DatePicker from 'components/DatePicker';

// styles
import styles from './styles';
import {
  SHIPPER,
  TL,
  CONSIGNEE,
  LTL,
  ADD,
  REMOVE,
  MAX_LOCATION_AUTOCOMPLETE_ITEMS,
} from 'constants/app';

class Stop extends Component {
  static propTypes = {
    accessorials: PropTypes.object,
    csr: PropTypes.bool,
    accessorialIds: PropTypes.array,
    holidays: PropTypes.array,
    theme: PropTypes.object,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    searchLocations: PropTypes.func,
    type: PropTypes.string,
    locationID: PropTypes.string,
    index: PropTypes.number,
    disabled: PropTypes.bool,
    zip: PropTypes.string,
    service: PropTypes.string,
    date: PropTypes.string,
    nextClicked: PropTypes.bool,
    newLocation: PropTypes.func,
    gatherErrorFunction: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { gatherErrorFunction, type, index } = this.props;
    this.state = {
      anchorEl: undefined,
    };

    gatherErrorFunction({
      errorFunction: this.hasError.bind(this),
      section: `${type} #${index + 1}`,
    }, ADD);
  }

  componentWillUnmount() {
    const { gatherErrorFunction, type, index } = this.props;
    gatherErrorFunction({
      errorFunction: this.hasError.bind(this),
      section: `${type} #${index + 1}`,
    }, REMOVE);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  hasError() {
    const {
      zip,
      locationID,
      type,
      date,
      service,
      csr,
    } = this.props;

    if ((service === TL) && (locationID === undefined) && !csr) return true;
    if (zip === '') return true;
    if ((type === SHIPPER) && (date === null)) return true;
    if ((type === SHIPPER) && (locationID === undefined) && !csr) return true;

    return false;
  }

  render() {
    const {
      type,
      onDelete,
      index,
      disabled,
      zip,
      nextClicked,
      onChange,
      newLocation,
      service,
      date,
      theme,
      accessorials,
      accessorialIds,
      searchLocations,
      locationID,
      csr,
      holidays,
    } = this.props;

    const { anchorEl } = this.state;

    const primaryColor = theme.palette.primary.color[500];

    let filteredAccessorials = [];
    if (service === LTL) {
      filteredAccessorials = accessorials.ltlAccessorials;
    }
    if (service === TL) {
      filteredAccessorials = accessorials.tlAccessorials;
    }

    const accessorialsToAdd = filteredAccessorials.filter(a => {
      return (
        (accessorialIds.find(aid => aid === a.accessorialId) === undefined)
        && (a.quoteVisible)
      )
    });

    return (
      <Card
        nextClicked={nextClicked}
        error={this.hasError()}
        primaryColor={primaryColor}
        disabled={disabled}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item style={{ paddingRight: 8 }}>
                {
                  type === SHIPPER
                    ? <ArrowUpward />
                    : <ArrowDownward />
                }
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {type} #{index + 1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} md={type === CONSIGNEE ? 6 : 4}>
            <TextField
              disabled={disabled}
              id="zip"
              label="Location"
              placeholder={csr ? 'Zipcode or City, State' : 'Zipcode'}
              fullWidth
              helperText={(csr ? zip.length === 0 : zip.length < 5) ? 'Required' : ''}
              value={zip}
              onChange={e => onChange(e, 'zip')}
              InputLabelProps={{
                shrink: true,
              }}
              error={(zip === '') && nextClicked}
            />
          </Grid>
          <Grid item xs={12} md={type === CONSIGNEE ? 6 : 4}>
            <LocationFinder
              maxLocationAutocompleteItems={MAX_LOCATION_AUTOCOMPLETE_ITEMS}
              error={((locationID === undefined) && nextClicked && (service === TL))
              || ((type === SHIPPER) && nextClicked && (locationID === undefined)) && !csr} // eslint-disable-line
              helperText={((type === SHIPPER) || (service === TL)) && !csr ? 'Required' : ''}
              zip={zip.length >= 5 ? zip : undefined}
              disabled={disabled || (csr ? !((zip.length === 5) && !isNaN(zip)) : zip.length < 5)} // eslint-disable-line
              searchLocations={searchLocations}
              service={service}
              onChange={e => onChange({ target: { value: e.target.value.id } }, 'locationID')}
              newLocation={newLocation}
            />
          </Grid>
          {
            type === SHIPPER
            && (
            <Grid item xs={12} md={4}>
              <DatePicker
                csr={csr}
                ltl={service === LTL}
                holidays={holidays}
                label={type === SHIPPER ? 'Pickup Date' : 'Drop-off Date'}
                value={date}
                helperText={(type === SHIPPER) && (date === null) ? 'Required' : ''}
                onChange={e => onChange({ target: { value: e } }, 'date')}
                renderDay={this.renderDay}
                error={(date === null) && nextClicked}
              />
            </Grid>
            )
          }
          {/* {
            type === SHIPPER
            && (
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={disabled}
                  id="time"
                  label={`${type} Time`}
                  placeholder="Chose Time"
                  type="time"
                  fullWidth
                  value={time}
                  onChange={e => onChange(e, 'time')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )
          } */}
          <Grid item>
            <Grid container alignItems="center">
              {
                accessorialsToAdd.length
                  ? (
                    <Grid item>
                      <Chip
                        avatar={(
                          <Avatar style={{ background: 'none' }}>
                            <Add />
                          </Avatar>
                        )}
                        variant="outlined"
                        label="Accessorials"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          if (disabled !== true) {
                            this.handleClick(e);
                          }
                        }}
                        style={{
                          opacity: disabled ? 0.5 : 1,
                        }}
                      />
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                      >
                        {
                          accessorialsToAdd.map((accessorial) => {
                            if (accessorial.quoteVisible) {
                              return (
                                <MenuItem
                                  onClick={() => {
                                    const newAccessorialIds = accessorialIds.slice();
                                    newAccessorialIds.push(accessorial.accessorialId);
                                    const e = {
                                      target: {
                                        value: newAccessorialIds,
                                      },
                                    };
                                    onChange(e, 'accessorialIds');
                                    this.handleClose();
                                  }}
                                  key={accessorial.accessorialId}
                                >
                                  <ListItemText
                                    primary={accessorial.name}
                                    secondary={`$${accessorial.costAmount} / ${accessorial.unitType}`}
                                  />
                                </MenuItem>
                              );
                            }
                            return [];
                          })
                        }
                      </Menu>
                    </Grid>
                  )
                  : []
              }
              {
                onDelete
                && (
                  <Grid item xs={12}>
                    <Button onClick={() => onDelete(type, index)} variant="contained" style={{ marginTop: 10 }}>
                      Remove Stop
                    </Button>
                  </Grid>
                )
              }
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ paddingLeft: 8, paddingTop: 8 }}>
            {
              accessorialIds.length
                ? (
                  <Typography variant="subtitle" gutterBottom>
                    Accessorials:
                  </Typography>
                )
                : (<div />)
            }
            <Grid container spacing={16}>
              {
                accessorialIds.map((aId, i) => {
                  const accessorial = filteredAccessorials.find(a => a.accessorialId === aId);
                  if (accessorial) {
                    return (
                      <Grid
                        item
                        key={`accessorials_${aId}`}
                      >
                        <Grow in>
                          <Chip
                            key={`accessorials_${aId}`}
                            label={`${accessorial.name} - $${accessorial.costAmount} / ${accessorial.unitType}`}
                            onDelete={() => {
                              const newAccessorialIds = accessorialIds.slice();
                              newAccessorialIds.splice(Number(i), 1);
                              const e = {
                                target: {
                                  value: newAccessorialIds,
                                },
                              };
                              onChange(e, 'accessorialIds');
                              this.handleClose();
                            }}
                          />
                        </Grow>
                      </Grid>
                    );
                  }
                  return [];
                })
              }
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default withTheme()(withStyles(styles)(Stop));

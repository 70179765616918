import {
  POST,
} from 'constants/methods';

const service = 'v3/quote-service';
const pallets = `/${service}/pallets`;

export function checkPallets(body) {
  const url = `${pallets}`;
  return {
    service,
    method: POST,
    body,
    url,
  };
}

export function palletCommodities(body) {
  const url = `${pallets}/commodities`;
  return {
    service,
    method: POST,
    body,
    url,
  };
}

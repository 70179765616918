import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Business from '@material-ui/icons/Business';
import MenuList from '@material-ui/core/MenuList';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

// images
import FindMap from './find.png';

// styles
import styles from './styles';

class CustomerFinder extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    searchCustomers: PropTypes.func,
    onChange: PropTypes.func,
    onCustomerSelect: PropTypes.func,
    selected: PropTypes.object,
    error: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { selected } = this.props;
    this.state = {
      searchText: selected ? selected.name : '',
      selected: undefined,
      archerSuggestions: [],
      customerLoading: false,
      open: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selected && (this.props.selected === undefined)) {
      this.setState({ searchText: nextProps.selected.name });
    }
  }

  async onCustomerSelect(customer, type) {
    const { onChange, onCustomerSelect } = this.props;
    this.handleClose();
    if (type === 'archer') {
      onChange({ target: { value: customer.id } });
      onCustomerSelect(customer);
    }
  }

  getLatLgn(address, cb) {
    this.geocoder.geocode({ address }, (results, status) => {
      cb(results, status);
    });
  }

  getMenuItems() {
    const { theme, maxcustomerAutocompleteItems, selected } = this.props;
    const { archerSuggestions } = this.state;
    const menuItems = [];

    const primaryColor = theme.palette.primary.color[500];

    for (const k in archerSuggestions) {
      if (archerSuggestions.hasOwnProperty(k)) {
        const s = archerSuggestions[k];
        menuItems.push(
          <MenuItem
            selected={selected && (selected.id === s.id)}
            key={s.id}
            onClick={() => this.onCustomerSelect(s, 'archer')}
          >
            <ListItemText
              primary={s.name}
              secondary={`${s.line1} ${s.state} ${s.zip}`}
            />
          </MenuItem>,
        );
      }
    }

    return menuItems.slice(0, maxcustomerAutocompleteItems);
  }

  handleClick = (event) => {
    const { disabled } = this.props;
    if (disabled) return;
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  handleChange(e) {
    const { searchCustomers } = this.props;
    const searchText = e.target.value;
    this.setState({
      searchText,
      customerLoading: true,
      open: true,
    });

    if (searchText === '') {
      this.setState({
        archerSuggestions: [],
        customerLoading: false,
      });
      return;
    }

    // Archer search
    searchCustomers(searchText, 0, 25).then((resp) => {
      this.setState({
        archerSuggestions: resp.payload,
        customerLoading: false,
      });
    });
  }

  render() {
    const {
      disabled,
      error,
      onChange,
      helperText,
      value,
      selected,
      onCustomerSelect,
    } = this.props;

    const {
      searchText,
      open,
      anchorEl,
      customerLoading,
    } = this.state;

    return (
      <div>
        <Tooltip title={''}>
          <Grid container spacing={8} alignItems="center">
            {
              selected || value
                ? (
                  <Grid item>
                    <Business color="primary" />
                  </Grid>
                )
                : []
            }
            <Grid item style={{ width: selected || value ? 'calc(100% - 88px)' : '100%' }}>
              <TextField
                error={error}
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick.bind(this)}
                onFocus={this.handleClick.bind(this)}
                disabled={disabled}
                id="zip"
                label="Customer"
                placeholder="Enter Customer"
                fullWidth
                helperText={helperText}
                value={searchText}
                onChange={this.handleChange.bind(this)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {
              selected
                ? (
                  <Grid item>
                    <IconButton
                      disabled={disabled}
                      size="small"
                      onClick={() => {
                        this.setState({
                          selected: undefined,
                          searchText: '',
                          archerSuggestions: [],
                          googleSuggestions: [],
                        });
                        onCustomerSelect(undefined);
                        onChange({ target: { value: undefined } });
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                )
                : []
            }
          </Grid>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom"
          disablePortal
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: 'scrollParent',
            },
          }}
          style={{ zIndex: 999, position: 'absolute', width: 'calc(100% - 20px)' }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom'
                  ? 'center top'
                  : 'center bottom',
              }}
            >
              <Paper style={{ maxHeight: 274, overflowY: 'scroll' }} elevation={12}>
                <ClickAwayListener onClickAway={this.handleClose.bind(this)}>
                  <MenuList
                    anchorEl={anchorEl}
                  >
                    {
                      !customerLoading
                        ? this.getMenuItems()
                        : <div />
                    }
                    {
                      customerLoading
                        ? (
                          <Grid container spacing={16} alignItems="center" justify="center">
                            <Grid item style={{ textAlign: 'center', marginTop: 77, marginBottom: 77 }}>
                              <Typography variant="h6">
                                Loading customers...
                              </Typography>
                              <CircularProgress />
                            </Grid>
                          </Grid>
                        )
                        : (
                          <div />
                        )
                    }
                    {
                      (searchText.length === 0) && !customerLoading
                        ? (
                          <Grid container spacing={16} alignItems="center" justify="center">
                            <Grid item style={{ textAlign: 'center', marginTop: 67, marginBottom: 67 }}>
                              <img alt="find customer" src={FindMap} height={60} />
                              <Typography variant="h6">
                                Type a Customer Name...
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                        : (
                          <div />
                        )
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default withTheme()(withStyles(styles)(CustomerFinder));

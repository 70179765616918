import {
  GET,
  POST,
} from 'constants/methods';

const service = 'v3';
const locations = `/${service}/locations`;

export function searchLocations(params) {
  const url = `${locations}/search${params}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function newLocation(body) {
  const url = `${locations}`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}
